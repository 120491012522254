import React from "react"
const Footer = () => {
  return (
    <footer className="footer">
      <div>
        <h4>
          &copy; 2012 - {new Date().getFullYear()}
          <span> &nbsp;&nbsp;&nbsp;Адвокат</span> &nbsp;Грибков А. В.
        </h4>
        <p>Лицензия № 02240/970 от 18.01.2001</p>
      </div>
    </footer>
  )
}

export default Footer
