import React from "react"
import logo from "../assets/logo2.png"
import { FaAlignRight, FaPhoneAlt } from "react-icons/fa"
import PageLinks from "../constants/links"
import { Link } from "gatsby"
const Navbar = ({ toggleSidebar }) => {
  return (
    <nav className="navbar">
      <div className="nav-center">
        <div className="nav-header">
          <Link to="/">
            <img src={logo} alt="advokat_mogilev" />
          </Link>
          <button type="button" className="toggle-btn" onClick={toggleSidebar}>
            <FaAlignRight></FaAlignRight>
          </button>
        </div>
        <PageLinks styleClass="nav-links" />
        <div className="nav-phone">
          <div className="nav-phone-icon">
            <FaPhoneAlt />
          </div>
          <p>+375 (29) 6645131</p>
          <p>+375 (29) 7407538</p>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
